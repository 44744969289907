





























































import mixins from 'vue-typed-mixins'
import CServicesField from '@/calendesk/sections/section/booking/components/CServicesField.vue'
import Booking from '@/calendesk/sections/section/booking/mixins/Booking'
export default mixins(Booking).extend({
  name: 'Booking3',
  components: {
    CServicesField
  },
  data () {
    return {
      menu: false
    }
  }
})
